export const LABELS = {
  editMilestone: 'Edit',
  newIssue: 'New issue',
  milestoneClosed: 'Closed ',
  milestoneOpen: 'Open',
  milestoneOverdue: 'Overdue by',
  separator: '•',
  milestoneLastUpdated: 'Last updated ',
  noDueDate: 'No due date',
  dueBy: 'Due by',
  milestoneError: 'Milestone cannot be loaded.',
  milestoneErrorMessage: 'This milestone cannot be loaded due to a system error.',
  milestoneIssuesError: 'Failed to load issues.',
  milestoneIssuesErrorMessage: "This milestone's issues can not be loaded due to a system error.",
  bulkActions: 'Bulk actions',
  markAs: 'Mark as',
  label: 'Label',
  assign: 'Assign',
  project: 'Project',
  milestone: 'Milestone',
  setIssueType: 'Issue type',
  numberOfResults: (numResults: number) => `${numResults} ${numResults === 1 ? 'result' : 'results'}`,
}
